import { Plugins } from '@capacitor/core';
var NativeModule = Plugins.BackgroundGeolocation;
var IGNORE_BATTERY_OPTIMIZATIONS = "IGNORE_BATTERY_OPTIMIZATIONS";
var POWER_MANAGER = "POWER_MANAGER";
var resolveSettingsRequest = function (resolve, request) {
    if (request.lastSeenAt > 0) {
        request.lastSeenAt = new Date(request.lastSeenAt);
    }
    resolve(request);
};
var DeviceSettings = /** @class */ (function () {
    function DeviceSettings() {
    }
    DeviceSettings.isIgnoringBatteryOptimizations = function () {
        return new Promise(function (resolve, reject) {
            NativeModule.isIgnoringBatteryOptimizations().then(function (result) {
                resolve(result.isIgnoringBatteryOptimizations);
            }).catch(function (error) {
                reject(error.message);
            });
        });
    };
    DeviceSettings.showIgnoreBatteryOptimizations = function () {
        return new Promise(function (resolve, reject) {
            var args = { action: IGNORE_BATTERY_OPTIMIZATIONS };
            NativeModule.requestSettings(args).then(function (result) {
                resolveSettingsRequest(resolve, result);
            }).catch(function (error) {
                reject(error.message);
            });
        });
    };
    DeviceSettings.showPowerManager = function () {
        return new Promise(function (resolve, reject) {
            var args = { action: POWER_MANAGER };
            NativeModule.requestSettings(args).then(function (result) {
                resolveSettingsRequest(resolve, result);
            }).catch(function (error) {
                reject(error.message);
            });
        });
    };
    DeviceSettings.show = function (request) {
        return new Promise(function (resolve, reject) {
            var args = { action: request.action };
            NativeModule.showSettings(args).then(function () {
                resolve();
            }).catch(function (error) {
                reject(error.message);
            });
        });
    };
    return DeviceSettings;
}());
export default DeviceSettings;
